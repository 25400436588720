import React from 'react';
import PropTypes from 'prop-types';
import { localeDate } from '~utils/dates';
import { Link as GatsbyLink } from 'gatsby';
import Button from '@material-ui/core/Button';
import products, { getProductPriceText } from '~utils/products';
import { getRequiredPlanName } from '~utils/required-subscription-plan';
import { StripeExpressCheckoutButton } from '~components/form-buttons';
import AccountPaymentHistory from '~components/account/AccountPaymentHistory';
import { SUBSCRIBE_PATH } from '~utils/subscribe-helpers';
import {
  EmailForm,
  PasswordForm,
  ProfileForm,
  EducationInfoForm,
  EducationStudentForm,
  CancelationPopup,
  PaypalUpdateInfo
} from './components/forms';

export const POPUPS_MAP = {
  EMAIL_FORM: 1,
  PASSWORD_FORM: 2,
  PROFILE_FORM: 3,
  EDUCATION_INFO_FORM: 4,
  EDUCATION_STUDENT_FORM: 5,
  PAYMENT_HISTORY: 6,
  CANCELATION_POPUP: 7,
  PAYPAL_UPDATE: 8
};

const LinkButton = ({ title, url }) => (
  <Button
    component={GatsbyLink}
    style={{
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      marginBottom: '16px',
      marginRight: '-8px',
      marginTop: '-6px'
    }}
    to={url}
    color="primary"
  >
    {title}
  </Button>
);
LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

const PopupButton = ({ title, onButtonClick, id }) => {
  const handleClick = () => {
    onButtonClick(id);
  };

  return (
    <Button
      style={{
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        marginBottom: '16px',
        marginRight: '-8px',
        marginTop: '-6px'
      }}
      color="primary"
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

PopupButton.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

export const calculateSubscriptionData = (user, isPrivilegedMember, isProducer, isEducator) => {
  let leftColItems;
  let rightColItems;

  const privilegedMemberPlanUpgradeText = isProducer || isEducator ? 'change plan' : 'manage plan';

  const button = {
    type: 1,
    url: SUBSCRIBE_PATH,
    title: isPrivilegedMember ? privilegedMemberPlanUpgradeText : 'upgrade'
  };

  if (!isPrivilegedMember) {
    leftColItems = [
      {
        text: 'Aleph Beta Plan'
      },
      {
        text: 'Limited'
      }
    ];

    rightColItems = [
      {
        text: 'Free'
      }
    ];
    return { leftColItems, rightColItems, button };
  }

  // It MUST be `subscription_plan_id` in this check, not the access level!
  const product = products.find(({ id }) => id === user.subscription_plan_id);
  const planName = getRequiredPlanName(user.access_level);

  const isYearly = user.subscription_plan_period === 'year';
  const priceText = getProductPriceText(product, isYearly);
  const billingDate = user.subscription_expires_at ? localeDate(user.subscription_expires_at) : '-';

  leftColItems = [
    {
      text: 'Aleph Beta Plan'
    },
    {
      text: planName
    },
    {
      text: 'Next Billing Date'
    }
  ];

  rightColItems = [
    {
      text: priceText
    },
    {
      text: billingDate
    }
  ];
  return { leftColItems, rightColItems, button };
};

export const calculateButtonComponent = (button, onButtonClick, onStripeSuccess, username) => {
  if (button.type === 1) {
    return <LinkButton title={button.title} url={button.url} />;
  }

  if (button.type === 2) {
    return (
      <StripeExpressCheckoutButton
        onSuccess={onStripeSuccess}
        username={username}
        panelLabel="Update Card Details"
        label="Update Card Details"
        description="Update your card details below"
      >
        <Button
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginBottom: '16px',
            marginRight: '-8px',
            marginTop: '-6px'
          }}
          color="primary"
        >
          update payment
        </Button>
      </StripeExpressCheckoutButton>
    );
  }

  if (button.type === 3) {
    return (
      <Button
        style={{
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          marginBottom: '16px',
          marginRight: '-8px',
          marginTop: '-6px',
          color: '#E5646F'
        }}
      >
        cancel
      </Button>
    );
  }

  return <PopupButton title={button.title} onButtonClick={onButtonClick} id={button.popupId} />;
};

// eslint-disable-next-line
export const calculatePaymentData = (user, isSubscriber) => {
  let leftColItems;
  let button;

  const rightColItems = user.has_failed_charges
    ? [
        {
          text: <small style={{ color: '#f43434' }}>Payment Failed</small>
        }
      ]
    : [];

  if (!isSubscriber) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'No payment information'
      }
    ];
    button = {
      type: 1,
      url: SUBSCRIBE_PATH,
      title: 'subscribe'
    };

    return { leftColItems, button };
  }

  if (user.subscription_gateway_stripe_id) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'Credit Card: ****'
      }
    ];

    button = {
      type: 2
    };

    return { leftColItems, rightColItems, button };
  }

  if (user.subscription_gateway_appstore_id) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'Apple App Store'
      }
    ];

    button = {
      type: 1,
      url: 'https://www.apple.com/app-store/',
      title: 'App Store'
    };

    return { leftColItems, rightColItems, button };
  }

  if (user.subscription_gateway_gplay_id) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'Google Play'
      }
    ];

    button = {
      type: 1,
      url: 'https://play.google.com/store/apps',
      title: 'Google Play Store'
    };

    return { leftColItems, rightColItems, button };
  }

  if (user.subscription_gateway_paypal_id) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'PayPal'
      }
    ];

    button = {
      type: 4,
      title: 'update payment',
      popupId: POPUPS_MAP.PAYPAL_UPDATE
    };

    return { leftColItems, rightColItems, button };
  }

  if (user.is_admin) {
    leftColItems = [
      {
        text: 'Payment Method'
      },
      {
        text: 'No payment information (Admin)'
      }
    ];

    button = {
      type: 1,
      url: SUBSCRIBE_PATH,
      title: 'subscribe'
    };

    return { leftColItems, button };
  }

  leftColItems = [
    {
      text: 'Payment Method'
    },
    {
      text: 'No payment information (Admin/Manual)'
    }
  ];

  button = {
    type: 1,
    url: SUBSCRIBE_PATH,
    title: 'subscribe'
  };

  return { leftColItems, button };
};
export const CancellationMode = {
  DIRECT: 'DIRECT', // Go directly to the `/account/billing/cancel` page
  CANCELLATION_POPUP: 'CANCELLATION_POPUP', // See the cancellation popup
  PAY_WHAT_YOU_CAN: 'PAY_WHAT_YOU_CAN' // Go to the `/pay-what-you-can-instead` page
};

/**
 * @param {CancellationMode} mode With this you control what happens when the person clicks the "cancel" button
 * @returns {{button: ({popupId: number, type: number, title: string}|{type: number, title: string, url: string}), leftColItems: [{text: string}]}}
 */
export const calculateCancellationData = ({ mode }) => {
  const leftColItems = [
    {
      text: 'Need to cancel your subscription?'
    }
  ];

  let button;
  switch (mode) {
    case CancellationMode.CANCELLATION_POPUP: {
      button = {
        type: 4,
        title: 'Cancel',
        popupId: POPUPS_MAP.CANCELATION_POPUP
      };
      break;
    }
    case CancellationMode.PAY_WHAT_YOU_CAN: {
      button = {
        type: 1,
        url: '/pay-what-you-can-instead',
        title: 'Cancel'
      };
      break;
    }
    case CancellationMode.DIRECT:
    default: {
      button = {
        type: 1,
        url: '/account/billing/cancel',
        title: 'Cancel'
      };
    }
  }

  return { leftColItems, button };
};

export const calculatePersonalData = user => {
  const leftColItems = [
    {
      text: 'Personal Info'
    },
    {
      text: `${user.meta_first_name || ''} ${user.meta_last_name || ''}`
    },
    {
      text: user.meta_address && `${user.meta_address}`
    },
    {
      text: `${user.meta_country || ' '} ${user.meta_city || ' '} ${user.meta_zip || ' '}`
    },
    {
      text: user.meta_phone && `${user.meta_phone}`
    },

    {
      text:
        !user.meta_phone &&
        !user.meta_country &&
        !user.meta_zip &&
        !user.meta_address &&
        !user.meta_first_name &&
        !user.meta_last_name &&
        !user.meta_city &&
        `No personal information to show.`
    }
  ];

  const button = {
    type: 4,
    title: 'update info',
    popupId: POPUPS_MAP.PROFILE_FORM
  };

  return { leftColItems, button };
};

export const calculateEmailData = user => {
  const leftColItems = [
    {
      text: 'Email'
    },
    {
      text: `${user.username}`
    }
  ];

  const button = {
    type: 4,
    title: 'change email',
    popupId: POPUPS_MAP.EMAIL_FORM
  };

  return { leftColItems, button };
};

export const calculatePasswordData = () => {
  const leftColItems = [
    {
      text: 'Password'
    },
    {
      text: `***************`
    }
  ];

  const button = {
    type: 4,
    title: 'change password',
    popupId: POPUPS_MAP.PASSWORD_FORM
  };

  return { leftColItems, button };
};
export const calculateTaxReceipts = () => {
  const leftColItems = [
    {
      text: 'Tax Receipts'
    }
  ];

  const button = {
    type: 4,
    title: 'VIEW',
    popupId: POPUPS_MAP.PAYMENT_HISTORY
  };

  return { leftColItems, button };
};

export const calculateEducationData = user => {
  const leftColItems = [
    {
      text: 'Education Info'
    },
    {
      text: `${user.education_school_name}`
    }
  ];

  const button = {
    type: 4,
    title: 'update info',
    popupId: POPUPS_MAP.EDUCATION_INFO_FORM
  };

  return { leftColItems, button };
};

export const calculatePopup = (
  popupId,
  handleClose,
  addStudent,
  user,
  isLoading,
  data,
  isError,
  error,
  handleCancelSubscription
) => {
  switch (popupId) {
    case POPUPS_MAP.EMAIL_FORM:
      return <EmailForm onClose={handleClose} />;
    case POPUPS_MAP.PASSWORD_FORM:
      return <PasswordForm onClose={handleClose} />;
    case POPUPS_MAP.PROFILE_FORM:
      return <ProfileForm onClose={handleClose} />;
    case POPUPS_MAP.EDUCATION_INFO_FORM:
      return <EducationInfoForm onClose={handleClose} />;
    case POPUPS_MAP.EDUCATION_STUDENT_FORM:
      return (
        <EducationStudentForm
          onClose={handleClose}
          handleStudentSubmit={addStudent}
          isLoading={isLoading}
          data={data}
          isError={isError}
          error={error}
        />
      );
    case POPUPS_MAP.PAYMENT_HISTORY:
      return <AccountPaymentHistory user={user} onClose={handleClose} />;
    case POPUPS_MAP.CANCELATION_POPUP:
      return (
        <CancelationPopup
          handleClose={handleClose}
          handleCancelSubscription={handleCancelSubscription}
        />
      );
    case POPUPS_MAP.PAYPAL_UPDATE:
      return <PaypalUpdateInfo onClose={handleClose} />;
    default:
      return null;
  }
};

export const calculateWatchHistory = () => {
  const leftColItems = [
    {
      text: 'Watch History'
    }
  ];

  const button = {
    type: 1,
    title: 'VIEW',
    url: '/watch-history'
  };

  return { leftColItems, button };
};
